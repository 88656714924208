












































































































































































































import LinkCard from '@/components/chat/InviteLinks/LinkCard.vue'
import PageTitle from '@/components/PageTitle.vue'
import InviteLink from '@/views/chat/invite-links/InviteLink.model'
import Api from '@/includes/logic/Api'
import inviteLinksStatistics from '@/mixins/statistics/InviteLinks/inviteLinksStatistics'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification, successNotification } from '@/includes/NotificationService'

import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'

import { Mixins, Watch } from 'vue-property-decorator'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import Component from 'vue-class-component'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import WindowResizeMixin from 'piramis-base-components/src/logic/helpers/WindowResizeMixin'

enum ModalModeType {
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete'
}

@Component({
  'components': {
    LinkCard,
    ConfigField,
    PageTitle,
    flatPickr,
    List
  },
  'mixins': [ inviteLinksStatistics ],
  data() {
    return {
      ListDirection
    }
  }
})
export default class LinkList extends Mixins(UseFields, InputSetups, WindowResizeMixin) {
  modalMode: ModalModeType = ModalModeType.Create

  ModalModeType: any = ModalModeType

  links: InviteLink[] = []

  linkStat: Object = {}

  linkModel: InviteLink = this.clearLinkModel

  dateTimeObject = {
    date: null,
    time: null
  }

  isPopupActive = false

  rawLinks = []

  @Watch('isPopupActive')
  onPopupActiveChange(value: boolean) {
    !value ? this.clearAllFields() : value
  }

  //popup title
  get popupMode(): string {
    switch (this.modalMode) {
      case ModalModeType.Delete:
        return `${ this.$t('link_list_remove') }`
      case ModalModeType.Edit:
        return `${ this.$t('link_list_edit') }`
      case ModalModeType.Create:
        return `${ this.$t('link_list_create') }`
      default:
        throw new Error('Unknown modal type')
    }
  }

  prepareLinkObject(): void {
    const linkTime = this.makeTimeString()
    const linkModelCopy = {
      ...this.linkModel,
      time: linkTime ? linkTime : null,
      chat_id: this.$store.state.chatState.chat.chat_id
    }
    const clearLinkToRequest = Object.fromEntries(Object.entries(linkModelCopy).filter(([ _, v ]) => v)) as InviteLink
    this.generateLink(clearLinkToRequest)
  }

  //api call to server - generate new link
  generateLink(linkObject: InviteLink): void {
    Api.createinvitelink('tg', linkObject)
      .then(({ data }) => {
        this.closePopupAndUpdateLinkList()
        successNotification()
      })
  }

  // //delete invite link
  deleteInviteLink(code: string): void {
    Api.deleteinvitelink('tg', { chat_id: this.$store.state.chatState.chat.chat_id, code: code })
      .finally(() => {
        this.closePopupAndUpdateLinkList()
      })
  }

  //edit base info in invite link
  editInviteLink(newLink: InviteLink): void {
    Api.setinvitelink('tg', { ...newLink, chat_id: this.$store.state.chatState.chat.chat_id })
      .then(() => {
        this.closePopupAndUpdateLinkList()
      })
  }

  //close popup and update link list
  closePopupAndUpdateLinkList() {
    this.$baseTemplate.loader.open()
    this.isPopupActive = false
    this.loadLinkAndStatistics()
  }

  //open modal window with params
  openModalWindow(link: InviteLink, mode: ModalModeType) {
    if (link) {
      this.linkModel = { ...link }
    }
    this.modalMode = mode
    this.isPopupActive = true
  }

  getInviteLinks = (): Promise<Array<InviteLink>> => new Promise((resolve, reject) => {
    Api.getInviteLinks('tg', this.$store.state.chatState.chat.chat_id)
      .then(({ data }) => resolve(data.links))
      .catch(err => {
        errorNotification(err)
        reject(false)
      })
  })

  getInviteLinksStat = (): Promise<any> => new Promise((resolve, reject) => {
    Api.getinvitelinksstat('tg', { chat_id: this.$store.state.chatState.chat.chat_id, interval: 'day' })
      .then(({ data }) => resolve(data.links))
      .catch(err => {
        errorNotification(err)
        reject(false)
      })
  })

  //get date+time
  makeTimeString(): string | null {
    if (this.dateTimeObject.date && this.dateTimeObject.time)
      return `${ this.dateTimeObject.date } ${ this.dateTimeObject.time }`
    else
      return null
  }

  modelSetter(args: FieldData): FieldData {
    args.setter = (value: any): void => {
      args.model[args.key] = value
    }
    return args
  }

  //clear fields
  clearAllFields(): void {
    this.linkModel = this.clearLinkModel
    this.resetDateTime()
  }

  resetDateTime(): void {
    this.dateTimeObject.date = null
    this.dateTimeObject.time = null
  }

  get getCalendarLocale(): any {
    return this.$i18n.locale === 'ru' ? Russian : 'default'
  }

  get isRightDate() {
    return moment(this.dateTimeObject.date).isBefore(moment(), 'day')
  }

  get clearLinkModel(): InviteLink {
    return {
      title: '',
      count: 0,
      time: '',
      tags: [],
      price: 0
    }
  }

  loadLinkAndStatistics(): void {
    Promise.all([ this.getInviteLinks(), this.getInviteLinksStat() ])
      .then((res) => {
        this.links = res[0]
        this.rawLinks = res[1]
        this.linkStat = inviteLinksStatistics.methods.prepareStatData(this, res[1], true)
      })
      .finally(() => this.$baseTemplate.loader.close())

  }

  get activeLinks() {
    const newLinks: any[] = []
    let linksCopy = cloneDeep(this.links)

    linksCopy.map((link: any) => {

      let enter_unique_first_users_sum = 0
      let enter_unique_repeated_users_sum = 0
      let leave_unique_users_sum = 0

      const linkWithStat: any = this.rawLinks.find((rawLink: any) => rawLink.link.code === link.code)

      if (linkWithStat) {

        linkWithStat.stat.forEach((item: any) => {
          enter_unique_first_users_sum += item.enter_unique_first_users
          enter_unique_repeated_users_sum += item.enter_unique_repeated_users
          leave_unique_users_sum += item.leave_unique_users
        })

        link.enter_unique_first_users = enter_unique_first_users_sum
        link.enter_unique_repeated_users = enter_unique_repeated_users_sum
        link.leave_unique_users = leave_unique_users_sum

        newLinks.push(link)
      }
    })
    return newLinks.sort((a: any, b: any) => {
      return b.enter_unique_first_users - a.enter_unique_first_users ||
        b.enter_unique_repeated_users - a.enter_unique_repeated_users ||
        b.leave_unique_users - a.leave_unique_users
    }
    )
  }

  get nonActiveLinks() {
    const newLinks: any[] = []
    this.links.forEach((link: any) => {
      const linkWithStat: any = this.rawLinks.find((rawLink: any) => rawLink.link.code === link.code)
      if (!linkWithStat) {
        newLinks.push(link)
      }
    })
    return newLinks.sort((a: any, b: any) => b.enter - a.enter || b.kick - a.kick)
  }

  mounted(): void {
    this.$baseTemplate.saveButton.hide()
    this.$baseTemplate.loader.open()
    this.loadLinkAndStatistics()
  }

  destroyed(): void {
    this.$baseTemplate.saveButton.show()
  }
}

