















































































































































import InviteLink from '@/views/chat/invite-links/InviteLink.model'

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { copyTokenToClipboard } from 'piramis-base-components/src/logic/helpers/copy-to-clipboard'
import { successNotification } from '@/includes/NotificationService'

@Component({
  'components': { VueApexCharts, Icon }
})
export default class LinkCard extends Vue {
  @Prop() link!: InviteLink

  @Prop() stat!: any

  @Prop() chartType !: string

  get getNeededMetrics() {
    if (this.stat) {
      return this.stat.series.filter((item: any) => item.name === this.$t('enter_unique_first_users') || item.name === this.$t('leave_unique_users') || item.name === this.$t('kick_unique_users') ? item : null)
    } else {
      return [
        {
          name: this.$t('invite_link_enter'),
          data: [ this.link.enter ],
          type: 'bar'
        },
        {
          name: this.$t('invite_link_kick'),
          data: [ this.link.kick ],
          type: 'bar'
        },
        {
          name: this.$t('invite_link_leave'),
          data: [ this.link.leave ],
          type: 'bar'
        }
      ]
    }
  }

  get barOptions() {
    return {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false
        }
      },
      grid: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'date',
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      legend: {
        show: false,
      },
      tooltip: {
        x: {
          format: 'YYYY-MM-DD HH:mm:ss',
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      }
    }
  }

  get lineOptions() {
    return {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false
        }
      },
      grid: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'date',
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      legend: {
        show: false,
      },
      tooltip: {
        x: {
          format: 'YYYY-MM-DD HH:mm:ss',
          show: false
        },
      },
    }
  }

  saveObjToStoreAndRoute(code: string) {
    this.$router.push({ name: 'invite_links_statistics', params: { linkCode: code } })
  }

  clearSubscriber(link: InviteLink): string | undefined {
    if (link.price && link.leave !== undefined && link.enter !== undefined && link.kick !== undefined) {
      const clearPrice = Number(link.price / Number(link.enter - link.kick - link.leave))

      if (clearPrice !== Infinity) {
        return clearPrice.toFixed(2)
      }

      return '-'
    }

  }

  dirtySubscriber(link: InviteLink): string | undefined {
    if (link.price && link.enter !== undefined) {
      const clearPrice = Number(link.price / Number(link.enter))

      if (clearPrice !== Infinity) {
        return clearPrice.toFixed(2)
      }

      return '-'
    }
  }

  copyLinkToClipboard(linkUrl: string) {
    const copy = copyTokenToClipboard(linkUrl)
    if (copy) successNotification(this.$t('copy_link_to_buffer').toString())
  }
}
